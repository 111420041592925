import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Run ZoneMinder inside a Docker Container",
  "path": "/Advanced_User/ZoneMinder_inside_a_Docker_Container/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ZoneMinder is intended for use in single or multi-camera video security applications. Use Docker to deploy your ZoneMinder Application on Linux, Windows or macOS. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_ZoneMinder.png",
  "social": "/images/Search/AU_SearchThumb_ZoneMinder.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ZoneMinder_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Zoneminder"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Run ZoneMinder inside a Docker Container' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ZoneMinder is intended for use in single or multi-camera video security applications. Use Docker to deploy your ZoneMinder Application on Linux, Windows or macOS. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ZoneMinder.png' twitter='/images/Search/AU_SearchThumb_ZoneMinder.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/ZoneMinder_in_einem_Docker_Container/' locationFR='/fr/Advanced_User/ZoneMinder_inside_a_Docker_Container/' crumbLabel="ZoneMinder" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "run-zoneminder-inside-a-docker-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#run-zoneminder-inside-a-docker-container",
        "aria-label": "run zoneminder inside a docker container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run ZoneMinder inside a Docker Container`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-of-docker-on-centos"
        }}>{`Installation of Docker on CentOS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#zoneminder-and-mysql"
        }}>{`Zoneminder and MySQL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#accessing-the-zoneminder-applications"
        }}>{`Accessing the Zoneminder applications`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-zoneminder"
        }}>{`Configuring ZoneMinder`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#firewalld"
            }}>{`FirewallD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-a-instar-1080p-camera"
            }}>{`Adding a INSTAR 1080p Camera`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#adding-your-cameras-mjpeg-stream"
                }}>{`Adding your cameras MJPEG Stream`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#adding-your-cameras-rtsp-stream"
                }}>{`Adding your cameras RTSP Stream`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#accessing-your-camera"
        }}>{`Accessing your Camera`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`ZoneMinder is intended for use in single or multi-camera video security applications, including commercial or home CCTV, theft prevention and child, family member or home monitoring and other domestic care scenarios such as nanny cam installations. It supports capture, analysis, recording, and monitoring of video data coming from one or more video or network cameras attached to a Linux system. ZoneMinder also support web and semi-automatic control of Pan/Tilt/Zoom cameras using a variety of protocols. It is suitable for use as a DIY home video security system and for commercial or professional video security and surveillance. It can also be integrated into a home automation system via X.10 or other protocols. If you're looking for a low cost CCTV system or a more flexible alternative to cheap DVR systems, then why not give ZoneMinder a try?`}</p>
    <h2 {...{
      "id": "installation-of-docker-on-centos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation-of-docker-on-centos",
        "aria-label": "installation of docker on centos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of Docker on CentOS`}</h2>
    <p>{`Docker can be installed on Linux, Windows and macOS. In this example we will use CentOS Linux - for other operating systems, please `}<a parentName="p" {...{
        "href": "https://docs.docker.com/install/"
      }}>{`check the official documentation`}</a>{`.`}</p>
    <p>{`First we need to add the repository to pull the Docker code from - type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nano /etc/yum.repos.d/virt7-docker-common-release.repo`}</code>{` and add:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`virt7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
name=virt7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release
baseurl=http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//cbs.centos.org/repos/virt7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release/x86_64/os/
gpgcheck=0`}</code></pre></div>
    <p>{`Then install `}<a parentName="p" {...{
        "href": "https://www.docker.com/"
      }}>{`Docker`}</a>{` on Centos server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`yum update
yum -y `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` --enablerepo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`virt7-docker-common-release docker
systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` docker 
systemctl start docker`}</code></pre></div>
    <h2 {...{
      "id": "zoneminder-and-mysql",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#zoneminder-and-mysql",
        "aria-label": "zoneminder and mysql permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zoneminder and MySQL`}</h2>
    <p>{`We will use the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/quantumobject/docker-zoneminder"
      }}>{`Zoneminder`}</a>{` from `}<a parentName="p" {...{
        "href": "https://github.com/QuantumObject"
      }}>{`QuantumObject`}</a>{` that requires a MySQL v5.7 server. To run with MySQL in a separate container use the command below:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker network create net
docker run -d -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Europe/Berlin -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MYSQL_USER`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`zmuser -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MYSQL_PASSWORD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`zmpass -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MYSQL_DATABASE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`zm -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MYSQL_ROOT_PASSWORD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mysqlpsswd -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MYSQL_ROOT_HOST`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`% --net net --name db mysql/mysql-server:5.7
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wait until MySQL startup..."`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "801px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb41d4f88ef22179dd5757d386cedef2/2ad15/Zoneminder_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA2UlEQVQY01XHj06FIByGYe8gDBD7px4TkgMHwZ+AGie3Wvd/S43Wam3P3n1fkYxNr/7qXNogBUh+ThGu4E7aNqNprWul7VrVNao76a7Xba/z7vRzowodFx5ABBBHELvPvQa+L8O6DBE4zFxbIScxXISaxMVy64Q0fDS61wW1M9k9XYDEhayeTo6qqdKWccMaVdOxxi81Gf+huYyOBdr8zeeGABDMKMXbB4UriZn8aS3x3Rk/quz+nO8vJoty82UKZYrlx4reV3TEfN++HTHbPOYme1KkkoT9+QLyqC19EeDkAgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb41d4f88ef22179dd5757d386cedef2/e4706/Zoneminder_01.avif 230w", "/en/static/fb41d4f88ef22179dd5757d386cedef2/d1af7/Zoneminder_01.avif 460w", "/en/static/fb41d4f88ef22179dd5757d386cedef2/d011e/Zoneminder_01.avif 801w"],
              "sizes": "(max-width: 801px) 100vw, 801px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb41d4f88ef22179dd5757d386cedef2/a0b58/Zoneminder_01.webp 230w", "/en/static/fb41d4f88ef22179dd5757d386cedef2/bc10c/Zoneminder_01.webp 460w", "/en/static/fb41d4f88ef22179dd5757d386cedef2/99a1d/Zoneminder_01.webp 801w"],
              "sizes": "(max-width: 801px) 100vw, 801px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb41d4f88ef22179dd5757d386cedef2/81c8e/Zoneminder_01.png 230w", "/en/static/fb41d4f88ef22179dd5757d386cedef2/08a84/Zoneminder_01.png 460w", "/en/static/fb41d4f88ef22179dd5757d386cedef2/2ad15/Zoneminder_01.png 801w"],
              "sizes": "(max-width: 801px) 100vw, 801px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb41d4f88ef22179dd5757d386cedef2/2ad15/Zoneminder_01.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then download and run the ZoneMinder container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d --shm-size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`4096m -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Europe/Berlin -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ZM_DB_HOST`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`db --net net --name zm -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`:80 quantumobject/docker-zoneminder`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/37bc330858826014ecddb3f16367d70e/d48f1/Zoneminder_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABE0lEQVQoz5WP4WrDIBRG8wjeq0m6mmYN/aNN2oaoVxPbjrHu/V9pGLYxsq4wOFzkyvH7zGoy0hg5WhmdDHZ9ITk6SUYGW9mhsqY69pumqwtdF2omHTaF2pUqY5NjV8/IABk2EtYtPnfYdGnWLW4PvNrzleblEix1BlcP7yM6i2QhUr7SuVB5/oVQotCivE8GZ4K3gMOAZGByYr1PF6sflA/kSOw2ojXoDUQST/sHUb/kC7HXAN/J/5MjsRcPxiQ50mftRfM/+qc/s1vAYZYnSttc3UHMcylPDs4OvYXo4EzY9lyfuDpy3WPbozqhnul6vjsu8jMcDHgLo8PgIFgIDrxFZ3BMb8FEOAy8OfDdgW+7hfwBFuhOSDAQt5wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37bc330858826014ecddb3f16367d70e/e4706/Zoneminder_02.avif 230w", "/en/static/37bc330858826014ecddb3f16367d70e/d1af7/Zoneminder_02.avif 460w", "/en/static/37bc330858826014ecddb3f16367d70e/9b205/Zoneminder_02.avif 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/37bc330858826014ecddb3f16367d70e/a0b58/Zoneminder_02.webp 230w", "/en/static/37bc330858826014ecddb3f16367d70e/bc10c/Zoneminder_02.webp 460w", "/en/static/37bc330858826014ecddb3f16367d70e/7fcbc/Zoneminder_02.webp 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37bc330858826014ecddb3f16367d70e/81c8e/Zoneminder_02.png 230w", "/en/static/37bc330858826014ecddb3f16367d70e/08a84/Zoneminder_02.png 460w", "/en/static/37bc330858826014ecddb3f16367d70e/d48f1/Zoneminder_02.png 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/37bc330858826014ecddb3f16367d70e/d48f1/Zoneminder_02.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can verify that both containers are running with - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker ps`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "804px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5f5824c06c986c2903a234d2ff4195b8/27b7a/Zoneminder_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.173913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAbklEQVQI1xXIQQ6DIBAFUG8g5dOFWgkgTcmMAwS0m6b3v5Zx9/IGn4rrzQdxVFxrk2WsDK4IgplAFTFjJvNMcDtSwcpmoju9DKr28TxUkEeU8Xeq/1cfXXOFZSyEd74ds1nI2F1TxaeYF2ETpHIB6j4QPbhduwsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f5824c06c986c2903a234d2ff4195b8/e4706/Zoneminder_03.avif 230w", "/en/static/5f5824c06c986c2903a234d2ff4195b8/d1af7/Zoneminder_03.avif 460w", "/en/static/5f5824c06c986c2903a234d2ff4195b8/4ee0d/Zoneminder_03.avif 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5f5824c06c986c2903a234d2ff4195b8/a0b58/Zoneminder_03.webp 230w", "/en/static/5f5824c06c986c2903a234d2ff4195b8/bc10c/Zoneminder_03.webp 460w", "/en/static/5f5824c06c986c2903a234d2ff4195b8/95e88/Zoneminder_03.webp 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f5824c06c986c2903a234d2ff4195b8/81c8e/Zoneminder_03.png 230w", "/en/static/5f5824c06c986c2903a234d2ff4195b8/08a84/Zoneminder_03.png 460w", "/en/static/5f5824c06c986c2903a234d2ff4195b8/27b7a/Zoneminder_03.png 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5f5824c06c986c2903a234d2ff4195b8/27b7a/Zoneminder_03.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "accessing-the-zoneminder-applications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessing-the-zoneminder-applications",
        "aria-label": "accessing the zoneminder applications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accessing the Zoneminder applications`}</h2>
    <p>{`After that check with your browser at addresses plus the port assigned by docker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "851px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8072133421f9e16b40f272b4d837ab0e/0fcea/Zoneminder_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABGklEQVQY032Ny0rDQBSG8xqC4ELBnQs3opuCsWibXkSw4CsVFETfQCy4UVyrBR9AXIi6sJlLzkxmYsEm04ItaeZIUqi48eOcnw8OnN9ZW9/cdsut1tFh86BecssbW3slt1prVr1GxatXvMZMiszHq+27O7vLK6sLi0tO+/jk9vomBAhe38h9l949wNOz1FqGSukoVDPJMywy+ux/+OTisnN6du5oraWUlDEOIFRIg4ALQShlnAsBnHPGGC+ghDBKOeej4RAR0zR1ej2fEhJpHUopIJBCKKWEkAAgZd4qQAQAiTFxHCdJYoyZjMd2Ok2McQihg0GMiNbafK3N7C9YMBfMMkScdB+/O1cjROfP7V/m7/o+VS/vX0n8AzR+IqJz6PoxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8072133421f9e16b40f272b4d837ab0e/e4706/Zoneminder_04.avif 230w", "/en/static/8072133421f9e16b40f272b4d837ab0e/d1af7/Zoneminder_04.avif 460w", "/en/static/8072133421f9e16b40f272b4d837ab0e/7aa96/Zoneminder_04.avif 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8072133421f9e16b40f272b4d837ab0e/a0b58/Zoneminder_04.webp 230w", "/en/static/8072133421f9e16b40f272b4d837ab0e/bc10c/Zoneminder_04.webp 460w", "/en/static/8072133421f9e16b40f272b4d837ab0e/155dd/Zoneminder_04.webp 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8072133421f9e16b40f272b4d837ab0e/81c8e/Zoneminder_04.png 230w", "/en/static/8072133421f9e16b40f272b4d837ab0e/08a84/Zoneminder_04.png 460w", "/en/static/8072133421f9e16b40f272b4d837ab0e/0fcea/Zoneminder_04.png 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8072133421f9e16b40f272b4d837ab0e/0fcea/Zoneminder_04.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://host_ip:port/zm/`}</code></pre></div>
    <p>{`Them log in with login/password : admin/admin , Please change password right away and check on-line `}<a parentName="p" {...{
        "href": "http://www.zoneminder.com/wiki/index.php/Documentation"
      }}>{`documentation`}</a>{` to configure ZoneMinder.`}</p>
    <p>{`To access the container from the server that the container is running:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it container_id /bin/bash`}</code></pre></div>
    <h2 {...{
      "id": "configuring-zoneminder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-zoneminder",
        "aria-label": "configuring zoneminder permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring ZoneMinder`}</h2>
    <h3 {...{
      "id": "firewalld",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#firewalld",
        "aria-label": "firewalld permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FirewallD`}</h3>
    <p>{`Before adding a camera make sure that the ports 80 and 554 are accessible on your server. If you are running `}<a parentName="p" {...{
        "href": "https://firewalld.org/documentation/"
      }}>{`FirewallD`}</a>{` use the following commands to open these ports:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`firewall-cmd --permanent --add-port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`554`}</span>{`/tcp
firewall-cmd --permanent --add-port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`/tcp
firewall-cmd --reload`}</code></pre></div>
    <h3 {...{
      "id": "adding-a-instar-1080p-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-a-instar-1080p-camera",
        "aria-label": "adding a instar 1080p camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a INSTAR 1080p Camera`}</h3>
    <p>{`Open the `}<strong parentName="p">{`Console`}</strong>{` tab and click on `}<strong parentName="p">{`Add`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "851px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01be8bc614641a415e9ae9af5ef6bb85/0fcea/Zoneminder_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB9ElEQVQoz02Oa2/SYBTH+y0MLcyYaLLSzU3ooPQ+RtY+z9OyCi1tiNALLF4SExM1+g184WVvcMpQFu6FTYZxiZ/C72RKo1nyy8nvnJx/zsEytMxwEsuL+QLHsMIew6WpbZKkSHLrP+n09k0o6v4muXX33iYmlwAjll6/erOczcNpOJrOz4eTwTgcTCKG03A0W8wvr2LCi+ViueoPJ3qllmNFzKg/MerPq40X5fpjWAs0u6U5Lc1p3yCaIDsi8rUoVVc1PUx3At05RnYb2YFqNYDlwVoALR+sgZav2QGw/DgGLA/Z0QHdaauWj+lOC9Z8YHnA8lXTWwcC8C8ctbUgrrHEjiL3MGA2ox/WANOFsZsuMD2l2lQqDdX01rhKpRmvQdN1HgUVx8OyNF3gRI6XeV5mRJnihJ1sluMlQSxyvCTJRWm/VGAFlhNzeabACrwgc4IsScVsNofduZ1KEngSxxMEkcOJp7dwfnd350Emv5cr7h8oCshkaDpDU2QaTyRSBJEkiBRBEDi+kUphz16+PekOvp7133V64en3PyfdcHY1X/0eX16fh6vxxa/B/Of4x3V3uPh02nvf6X38/O1D5+xLf9QMjjFRloGmA4geVixwZHAQHRwqh4qiAASQDpCuAqQCTQFQKxsqRFrZAEgrHxl5pvAXkUfKQ7I3YOYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01be8bc614641a415e9ae9af5ef6bb85/e4706/Zoneminder_05.avif 230w", "/en/static/01be8bc614641a415e9ae9af5ef6bb85/d1af7/Zoneminder_05.avif 460w", "/en/static/01be8bc614641a415e9ae9af5ef6bb85/7aa96/Zoneminder_05.avif 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01be8bc614641a415e9ae9af5ef6bb85/a0b58/Zoneminder_05.webp 230w", "/en/static/01be8bc614641a415e9ae9af5ef6bb85/bc10c/Zoneminder_05.webp 460w", "/en/static/01be8bc614641a415e9ae9af5ef6bb85/155dd/Zoneminder_05.webp 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01be8bc614641a415e9ae9af5ef6bb85/81c8e/Zoneminder_05.png 230w", "/en/static/01be8bc614641a415e9ae9af5ef6bb85/08a84/Zoneminder_05.png 460w", "/en/static/01be8bc614641a415e9ae9af5ef6bb85/0fcea/Zoneminder_05.png 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01be8bc614641a415e9ae9af5ef6bb85/0fcea/Zoneminder_05.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the `}<strong parentName="p">{`General`}</strong>{` tab give your camera a name and choose `}<strong parentName="p">{`Remote`}</strong>{` as `}<strong parentName="p">{`Source Type`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "798px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b0cc7be69f41df874ea69140ddd57881/898f6/Zoneminder_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACj0lEQVQ4y3WS204TQRjH91V2tt2dncOet3vo7Ha7hS0tLS3loA1PQokxiglguZAEkGBCTNTX8cY7goigF1LayhuYdqUUoskvc/HNd/h/B+7L59OfV1ffL65OT7+enZ2ff7u8uPzxq9fv9YfXvcH1zeC6N+j1h/3BbcrNYNgfDPvD2/7wN/eqe/Ti9dvnu8ed3Xfr3eON7tHGzmFn+6CzfbC+td/ZOVzf2n/WPdrcO3n55mRz7/32waet/Y9jPnCAtTLBCoyeyHFbLq2hwjJmzQmyX4d+XfJqvJXwxoxgJRm7nCLYZU4hiq6ZvusH+TBgBZYPg6AYBBFjUaFQqlYXFhaWq9WGZdkIIUHg7wGAMwzbspxsFkoSEkV56oUIqSsra9VKw3ODnO0bhgMhkWU6RpEkxOm6xVik6/bUxwgICSF6q9WOogQAcZwdTzmMgynVPI8Zhp0WnCCKMkZKa7Fdq7UYi8Iwxlid9hFFyKmq7jh5CDGEZBpJwoRojcZqktQo1SnVUzl3UEmSRz0HQUypjpDySLaqmq4XPFT7UDZCxDBMTdNFURKETCaTTQFAwJg6js/zYGJ8SIZTVcO2vUfTuqtsMRYTov+3smk5pVLZdRnCqixThJQUWaaEGJVKs1CYnRinUCHEo4G5bt5x86aZk2WSFeH9tLG6tNTO5RgA2Ue7+DttTTNcN++6vucxx/ExViangpBSr7fm55tJUrMsT5JQuoX7YMtyomiWsSifLwRB0fdDVbVkeaSNUnNx8WmzuVqpNOJ4LgxnisWypo3OCSFFkvCosuP4EKb7IGm347VjhKid8zyPqaqZBowz6oSoimJgTDkAAM8DQcj8E54HPM8DIEwscSkJw7g8Nx9FpT9ZMv/kcZTtJAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0cc7be69f41df874ea69140ddd57881/e4706/Zoneminder_06.avif 230w", "/en/static/b0cc7be69f41df874ea69140ddd57881/d1af7/Zoneminder_06.avif 460w", "/en/static/b0cc7be69f41df874ea69140ddd57881/7d2e5/Zoneminder_06.avif 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b0cc7be69f41df874ea69140ddd57881/a0b58/Zoneminder_06.webp 230w", "/en/static/b0cc7be69f41df874ea69140ddd57881/bc10c/Zoneminder_06.webp 460w", "/en/static/b0cc7be69f41df874ea69140ddd57881/ce206/Zoneminder_06.webp 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0cc7be69f41df874ea69140ddd57881/81c8e/Zoneminder_06.png 230w", "/en/static/b0cc7be69f41df874ea69140ddd57881/08a84/Zoneminder_06.png 460w", "/en/static/b0cc7be69f41df874ea69140ddd57881/898f6/Zoneminder_06.png 798w"],
              "sizes": "(max-width: 798px) 100vw, 798px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b0cc7be69f41df874ea69140ddd57881/898f6/Zoneminder_06.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "adding-your-cameras-mjpeg-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#adding-your-cameras-mjpeg-stream",
        "aria-label": "adding your cameras mjpeg stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your cameras MJPEG Stream`}</h4>
    <p>{`For the JPG stream choose:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Protocol`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HTTP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Host Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`username:password@camera-ip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/tmpfs/snap.jpg`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1920`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1080`}</td>
        </tr>
      </tbody>
    </table>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "799px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/03c38fdea9d52168d85afe232fc00bdf/76cea/Zoneminder_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACW0lEQVQoz2XR7U/TQBwH8P4psvWe22tva8tYH8e6bmW0G7AYJBg0xCgvjIBE8YWJIxKN8pf5zje+4EHAxAQ24G8wbR0zMfnkcun9vvfr3Unfv/34fXF+eX55fHJ6cnx6dnbx8+LX1dV4NL4bjW9zd6Px3fX49q/RzfX4pphLmzvDjVfDJ68PH+99Wts9XN8err98v7H94enuwZOd4cTB5t7h5t7HZ28+b707erH/dWv/6PnbLxJxEj1cxXaK7FQPV/XGcsVf0txUcxPh93Wvl4+pLPwSd0qaW9KnpKoQgRdwpjBMLMO0TMs0ZrtxGoWxaViWYVnmrGlYCMjlmQdyuTRVmpFUrtfrHiYKJgpEFCIqy6i7sJwmA0o5QhRhhjCjlDOmEapOcISIxLnwvHldrxKiUMop5Ziocbw0GKwlyXK3uxTHvSBoOc68bTcURc/LVMbyMMYEYVIuy7IMC+UyaDbbUbQQhp12uxvHqRCGLEOE8H1NUZZ1rlRMQlRKVUIyGCudTjpYWev3HjYabd8PXXfecRq2HTCWdc7LOIRE0jThuoFt+4ZZy/MKxiyO+8srjzrtRAiLkOwnC3myoGZhxnh9zq3V6vW6y7mAEAOAFhdXPK9ZKgGECIRTCE0BgCTOK57XdJzA95tCmHkfLQxjIUyMlfw4/H+MaQhRSVX1IGiaxqyi6JRmB8aYtaMkihZdtxkEEefVYot8KVvNKRBiSVH43JxTrVoIFV8ZhKTV6vZ7A99vBUF0f1uT2CQMsJQ/EpBlAAAsJv/IngQAVJDLAEIUhh3b9uM4qdXsP0Ls3IlNDcVyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03c38fdea9d52168d85afe232fc00bdf/e4706/Zoneminder_07.avif 230w", "/en/static/03c38fdea9d52168d85afe232fc00bdf/d1af7/Zoneminder_07.avif 460w", "/en/static/03c38fdea9d52168d85afe232fc00bdf/eb911/Zoneminder_07.avif 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/03c38fdea9d52168d85afe232fc00bdf/a0b58/Zoneminder_07.webp 230w", "/en/static/03c38fdea9d52168d85afe232fc00bdf/bc10c/Zoneminder_07.webp 460w", "/en/static/03c38fdea9d52168d85afe232fc00bdf/a2266/Zoneminder_07.webp 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03c38fdea9d52168d85afe232fc00bdf/81c8e/Zoneminder_07.png 230w", "/en/static/03c38fdea9d52168d85afe232fc00bdf/08a84/Zoneminder_07.png 460w", "/en/static/03c38fdea9d52168d85afe232fc00bdf/76cea/Zoneminder_07.png 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/03c38fdea9d52168d85afe232fc00bdf/76cea/Zoneminder_07.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "adding-your-cameras-rtsp-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#adding-your-cameras-rtsp-stream",
        "aria-label": "adding your cameras rtsp stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your cameras RTSP Stream`}</h4>
    <p>{`For the JPG stream choose:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Protocol`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RTSP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Method`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RTP/RTSP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Host Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`username:password@camera-ip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`554`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Path`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/11`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1920`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1080`}</td>
        </tr>
      </tbody>
    </table>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "799px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f76f8ed1b90b374561a62120f283de7b/76cea/Zoneminder_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACpUlEQVQoz2XRS2/TWBQAYP8VYt/HuU9fJ7Ed3zi24yRNidI2TRYTNItRBUUVAiQEtIBggUTLSwL+FytWLApMywgJaGF+wijxUFVC+hbn6h7pvJx3b99/OT7+fPT58PDjh8OPn/4+Pjr65+u305PTf5d+VsH3k5+//KiCk9Mfzs7dJ1du72/vPd/ae/HX3adbd/a3bj2+fOfJ1d2Dq3tPt3cPtnf3t3cPdu4927n/7NqDlzcfvbnx8PXNR6+vP3zl0Pa66s1JewPbDdWb62JmsomfTVS6LuxYpWuLuLPh1bs1P6uZ3DVFzeQVp26Cbt7VUnPgcRi3wjiOkjhspTabrM86aR42oiiMCcZu7YLn1s654Aih4jjBBDBhHqIeogjRmouEMNPpvNdbjaI2gCSEUcorZAlj6igV5HnfmCZjmnO/AqDq9dZ089Js+ud0emk02lwZjNO0zLJ+HKcAUgifUuZQCgAcIXye63pS6tFord8f9nrD4XBUdgeLmgQwplWO5yFHSq11gDEQws56wxh8v7GyslaWF8tyNc8HWdZPkszavNFoEQIAYtG29oMsK9vtIgwTzn3GNGOaUhkE8eZ0Ph7PimIohBHCSBlIGQhhqgEJYQ7nIknSVqudJKnWBmNKKUOI+H59MvnDmAZCBGNYohVCgBBAiDhK+u12YW2WdcogCAEk55pS0WwmWd5nTAMIAFl1tKSW9KKylDrPyzBsKWUYk5QKSgXGzJhwOBynadfaPI7TszsBLBKWx6MO59LaTrMRYfz/tghZ3FCpYDadX1xdL4pBUQyszTudsl6PzqZACDuMyzBKoiiJIxtFSbMZc64xhiBoGtOklDMmGZOcK84VgJTSF0IrFQAIx3U9z0O/c13v/Nfy6SJEer0Vazurw1HSsv8BvajpZx9kmXsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f76f8ed1b90b374561a62120f283de7b/e4706/Zoneminder_08.avif 230w", "/en/static/f76f8ed1b90b374561a62120f283de7b/d1af7/Zoneminder_08.avif 460w", "/en/static/f76f8ed1b90b374561a62120f283de7b/eb911/Zoneminder_08.avif 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f76f8ed1b90b374561a62120f283de7b/a0b58/Zoneminder_08.webp 230w", "/en/static/f76f8ed1b90b374561a62120f283de7b/bc10c/Zoneminder_08.webp 460w", "/en/static/f76f8ed1b90b374561a62120f283de7b/a2266/Zoneminder_08.webp 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f76f8ed1b90b374561a62120f283de7b/81c8e/Zoneminder_08.png 230w", "/en/static/f76f8ed1b90b374561a62120f283de7b/08a84/Zoneminder_08.png 460w", "/en/static/f76f8ed1b90b374561a62120f283de7b/76cea/Zoneminder_08.png 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f76f8ed1b90b374561a62120f283de7b/76cea/Zoneminder_08.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "accessing-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessing-your-camera",
        "aria-label": "accessing your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accessing your Camera`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "847px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/b2cef/Zoneminder_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQUlEQVQoz23Oe2/SUBQA8H4NoGTZi7S9t4BbmLCuLW2htKXMrYW2sPEqoItmZurYsmQYjZl/CM64LXsAzjiUPTV+RFNY5hK9+eXk3HPPOblImErMMjGG41k+xnACF0uIihqTknHZIcrqfygpLi7RnIDQXCI0y1arT67PL/pn33s/Lr72+mf9y7P+Zf/y5vrn76t/nF/9KtdWwjSHpKyVxaXVdH5NW36qWtVUtqZa1Tupe/E+SS+KWgFRjMp87rFqVpNmRVkoKHpRMWw5Ux6S0qWkaQ9j0qxI6ZJi2KpZSVlV2bAROVNWDKdJNsqyXpIHz/LfeXu4SzHKd0sVwx6OIAmtkNAKkl50pB2ilh8WRS0fX1h2rnph8M/bRNKLmVzFyJWQ4INpao6lWYFmeJrlKTo6E46wUUGUVD4mcXxcndc4IcGwsXCEilAMM+iMRgVqLop4vajH7XK7HE7idnlRdHRkZHpqSoyLAi+EH0Ymxie8KIp63B63a8hp9rgRlo+nrZxpZa3sUsbMPtKMtZcb9a0365uN7dfvGm/fv9rYXt9srL6oLxpZ3czqhiNj5hR1Hqk9e77T6e12v7U7p3sn3d1256h92jpuf+52DztfWgedj4fdT0fd5n67ddBu7p80908+7B0fnfZ2mrvIKMS2cLI+CccACM9MR1mKhJCEGCQxgsAgQUDgIMFt4lQIPBDwBwMBBPX5CuOT9tjkCI4H/YFQKEQQBOYcHMNwAEgI/RD6ASQJAAgACQIAAHEc9/l8fwBlxOjcdFdR9AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c70bf7196ce19b5c26f54fec7a0decbf/e4706/Zoneminder_09.avif 230w", "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/d1af7/Zoneminder_09.avif 460w", "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/97edd/Zoneminder_09.avif 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c70bf7196ce19b5c26f54fec7a0decbf/a0b58/Zoneminder_09.webp 230w", "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/bc10c/Zoneminder_09.webp 460w", "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/8a8c4/Zoneminder_09.webp 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c70bf7196ce19b5c26f54fec7a0decbf/81c8e/Zoneminder_09.png 230w", "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/08a84/Zoneminder_09.png 460w", "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/b2cef/Zoneminder_09.png 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c70bf7196ce19b5c26f54fec7a0decbf/b2cef/Zoneminder_09.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "847px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/03f38562261626076c4a02fdb20ee380/b2cef/Zoneminder_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.21739130434781%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAD00lEQVQ4y12Q22/adhTH/bqHPVZJyYWbMSQkqZImGLAxOCGxsY0h+H7DBhtzC00oCaxJWHNdVqlr97aHrZlQkjbqHvYvTtCtm/bTR199z/d3jo50gOVnKIxkUmk8s5mNI9jaRjyysBSCIiFo4SsQtACF/yUSiYbDiyEoAqA4AaO4W2v++fmPx7uH0d3HX29HH0Z3t6P729H976P7D6P70cOnh8fPX7l7eDQsZyOOAnm5XlQ7nH5Q0luM4rCqy6q1iboF1S1oEzPRv5OJyQkWwZUBSrRZ1WUkh5JsumjQJZMSqzmh8gVSsGipSvJjZSaGEqq0VM3LTk6sAjnByvEWyZk5sUJxJjUxOaFCTfLcuLsynhEr1Dgfl2MvVEjOBGjJoeUaJdkEX/kfpFD9Z/+43PnPF6/VZN0BEErJlqxMsRzbESaIMPEFKVMsk7tGgVe3GGk9y8OEGCelGCFubHNxQkRIBViMriDYFoJlNrcJgmboAlvkOUGVZV1VdK1smaqubpNZhqVNy9QNXVak3d1iocjCcRhYWVndJpjtndw2SdIsUxJKsq6Y1UqlZjsN12k4hVKeLeYbjXq97tp2xTTLmqYosoggCWBtbX2Xk+l8gWKYfCFfEjjdMmy3Vm81m+2mqAj5Al1vNfY67XqjXqs5lmVpmqooEpJMAKEQiKVSgigpmsaJPMeXFF1xGrXWi7ZaVnIM6Tbd/Zf77U671W66dbdqVw3DUDUFQZKA1+f1+ueiSwskuVO2ylbVlFVRM9TxZJ6sunZv0OscdPb2O+1Ou95s2I5dNg1VVZLJBOD3e2f9nlmfx+ufXY5CWRwzTN2yzXyRMSx9cDro9rv7h/ud7ovOQae913LrdaNscDwHwzCwtLyE4CgYmp+bn/Y9fUJsZRA0geOYIPLNvWa3//LV96/6J/3eoHfY73V7XdOyWDbPskw8HgeWny0nUBj0z/rmPc+jkTfv3gyvX3Myn0jCaQzVdPXwu8OLm4vzH86Ph8c8X0LRBM3QoiiiKApEV5aii1DQN+f1PCXw9PByeHp+cvX2+vjiVDLkZDymKpJdq/QGPUVXVhdC4ZlptlhQVBXDsPG1Qe8M6J+P+AM3b9//9unj65vLk8vh1U/X7375mcrtXF2d9ftHGBJPbqzmNjHft9+kErCm6el0BoBAKAJFwmB4EYK6g+HZj++PhmeGXdXNstN001iq0WoeDQZpJAl55+CN54GpJ4shcCtLxGIJAAShEBQBQSgQCHieTk9PTc14PLF1OJPGcTy7iW+lUpm1tXWf3x8IBH0+fyAYDIxfMBgE/wIjtWthJIv//QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03f38562261626076c4a02fdb20ee380/e4706/Zoneminder_10.avif 230w", "/en/static/03f38562261626076c4a02fdb20ee380/d1af7/Zoneminder_10.avif 460w", "/en/static/03f38562261626076c4a02fdb20ee380/97edd/Zoneminder_10.avif 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/03f38562261626076c4a02fdb20ee380/a0b58/Zoneminder_10.webp 230w", "/en/static/03f38562261626076c4a02fdb20ee380/bc10c/Zoneminder_10.webp 460w", "/en/static/03f38562261626076c4a02fdb20ee380/8a8c4/Zoneminder_10.webp 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03f38562261626076c4a02fdb20ee380/81c8e/Zoneminder_10.png 230w", "/en/static/03f38562261626076c4a02fdb20ee380/08a84/Zoneminder_10.png 460w", "/en/static/03f38562261626076c4a02fdb20ee380/b2cef/Zoneminder_10.png 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/03f38562261626076c4a02fdb20ee380/b2cef/Zoneminder_10.png",
              "alt": "ZoneMinder & Docker",
              "title": "ZoneMinder & Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      